<script setup lang="ts">
import { cva } from "class-variance-authority";

export interface Props {
  link: string;
  label: {
    en: string;
    fr: string;
  };
  icon: string;
}

const props = withDefaults(defineProps<Props>(), {
  link: "#",
  icon: "circle",
});

const isActive = computed(() => {
  return useRoute().path === props.link;
});

const icon = computed(() => {
  return `ic:${isActive.value ? "round" : "outline"}-${props.icon}`;
});

const iconClass = computed(() => {
  return cva("size-6", {
    variants: {
      isActive: {
        true: "text-on-secondary-container",
        false: "text-on-surface-variant",
      },
    },
  })({
    isActive: isActive.value,
  });
});

const labelClass = computed(() => {
  return cva("text-sm font-medium", {
    variants: {
      isActive: {
        true: "text-on-secondary-container font-semibold",
        false: "text-on-surface-variant",
      },
    },
  })({
    isActive: isActive.value,
  });
});

const stateLayerClass = computed(() => {
  return cva("flex px-4 py-1 rounded-full", {
    variants: {
      isActive: {
        true: "group-hover:bg-on-secondary-container/10 group-focus-visible:bg-on-secondary-container/20 group-active:bg-on-secondary-container/20",
        false:
          "group-hover:bg-on-surface/10 group-focus:bg-on-surface/20 group-active:bg-on-surface/20",
      },
    },
  })({
    isActive: isActive.value,
  });
});

const iconContainerClass = computed(() => {
  return cva("rounded-full", {
    variants: {
      isActive: {
        true: "bg-secondary-container",
      },
    },
  })({
    isActive: isActive.value,
  });
});

const { locale } = useI18n();
</script>

<template>
  <NuxtLink
    :to="props.link"
    class="group flex flex-col items-center gap-1 rounded-full focus:outline-none"
  >
    <div :class="iconContainerClass">
      <div :class="stateLayerClass">
        <Icon :name="icon" :class="iconClass" />
      </div>
    </div>
    <span :class="labelClass">{{ locale === "fr" ? label.fr : label.en }}</span>
  </NuxtLink>
</template>
