<script setup lang="ts">
import { cva } from "class-variance-authority";

type Props = {
  link: string;
  label: { fr: string; en: string };
  icon: string;
};

const props = withDefaults(defineProps<Props>(), {
  link: "#",
  icon: "circle",
});

const isActive = computed(() => {
  return useRoute().path === props.link;
});

const icon = computed(() => {
  return `ic:${isActive.value ? "round" : "outline"}-${props.icon}`;
});

const iconClass = computed(() => {
  return cva("size-6", {
    variants: {
      isActive: {
        true: "text-on-secondary-container",
        false: "text-on-surface-variant",
      },
    },
  })({
    isActive: isActive.value,
  });
});

const labelClass = computed(() => {
  return cva("text-base font-medium ml-3", {
    variants: {
      isActive: {
        true: "text-on-secondary-container font-semibold",
        false: "text-on-surface-variant",
      },
    },
  })({
    isActive: isActive.value,
  });
});

const containerClass = computed(() => {
  return cva("rounded-full", {
    variants: {
      isActive: {
        true: "bg-secondary-container",
      },
    },
  })({
    isActive: isActive.value,
  });
});

const stateLayerClass = computed(() => {
  return cva("py-4 pl-4 pr-6 flex items-center rounded-full", {
    variants: {
      isActive: {
        true: "bg-secondary-container group-hover:bg-on-secondary-container/10 group-focus-visible:bg-on-secondary-container/20 group-active:bg-on-secondary-container/20",
        false:
          "group-hover:bg-on-surface/10 group-focus:bg-on-surface/20 group-active:bg-on-surface/20",
      },
    },
  })({
    isActive: isActive.value,
  });
});

const { locale } = useI18n();
</script>

<template>
  <NuxtLink
    :to="props.link"
    class="group focus:outline-none"
    @click="useIsDrawerOpen().value = false"
  >
    <div :class="containerClass">
      <div :class="stateLayerClass">
        <Icon :name="icon" :class="iconClass" />
        <span :class="labelClass">
          {{ locale === "fr" ? label.fr : label.en }}
        </span>
      </div>
    </div>
  </NuxtLink>
</template>
