interface NavItem {
  label: {
    en: string;
    fr: string;
  };
  icon: string;
  link: string;
}

export const primaryNavItems: NavItem[] = [
  // {
  //   label: { en: "Dashboard", fr: "Dashboard" },
  //   icon: "dashboard",
  //   link: "/app/dashboard",
  // },
  // {
  //   label: { en: "Clients", fr: "Clients" },
  //   icon: "people",
  //   link: "/app/clients",
  // },
  {
    label: { fr: "Ventes", en: "Sales" },
    icon: "insert-chart",
    link: "/app/sales",
  },
  {
    label: { en: "Expenses", fr: "Dépenses" },
    icon: "payments",
    link: "/app/expenses",
  },
  {
    label: { fr: "Produits", en: "Products" },
    icon: "category",
    link: "/app/products",
  },
  {
    label: { fr: "Stock", en: "Stock" },
    icon: "inventory-2",
    link: "/app/stock",
  },
  {
    label: { en: "Employees", fr: "Employés" },
    icon: "assignment-ind",
    link: "/app/employees",
  },
  {
    label: { en: "Suppliers", fr: "Fournisseurs" },
    icon: "local-shipping",
    link: "/app/suppliers",
  },
  // {
  //   label: { fr: "Factures", en: "Invoices" },
  //   icon: "description",
  //   link: "/app/invoices",
  // },
];

export const secondaryNavItems: NavItem[] = [
  // {
  //   label: { fr: "Profil", en: "Profile" },
  //   icon: "person",
  //   link: "/app/profile",
  // },
  {
    label: { fr: "Paramètres", en: "Settings" },
    icon: "settings",
    link: "/app/settings",
  },
];
