<script setup lang="ts">
const logout = async () => {
  await $fetch("/api/auth/logout", {
    method: "POST",
  });

  useIsAuthed().value = false;

  await navigateTo("/auth/login");
};

const { t } = useI18n({
  useScope: "local",
});
</script>

<template>
  <UIDialog :title="t('title')" :description="t('description')">
    <template #trigger>
      <button class="flex flex-col items-center gap-1 p-2" type="button">
        <Icon name="ic:outline-logout" class="size-6 text-error" />
        <span class="text-sm font-medium text-error">{{ t("logoutBtn") }}</span>
      </button>
    </template>

    <template #action>
      <UIButton
        :label="t('logoutBtn')"
        type="button"
        variant="destructive"
        @click="logout"
      />
    </template>
  </UIDialog>
</template>

<i18n lang="json">
{
  "en": {
    "title": "Logout",
    "description": "Are you sure you want to logout?",
    "logoutBtn": "Logout"
  },
  "fr": {
    "title": "Déconnexion",
    "description": "Êtes-vous sûr de vouloir vous déconnecter?",
    "logoutBtn": "Déconnexion"
  }
}
</i18n>
