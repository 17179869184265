<script setup lang="ts">
const isDrawerOpen = useIsDrawerOpen();

watch(isDrawerOpen, (newVal) => {
  if (newVal) {
    document.body.style.overflow = "hidden";
  } else document.body.style.overflow = "auto";
});
</script>

<template>
  <div>
    <div class="fixed inset-0 h-screen w-fit">
      <LayoutAppNavRail class="hidden sm:flex lg:hidden" />
      <LayoutAppNavDrawer type="standard" class="hidden lg:flex" />
    </div>

    <LayoutAppTopBar class="sticky inset-0 z-20 sm:hidden" />
    <LayoutAppNavDrawer type="modal" class="sm:hidden" />

    <div class="sm:pl-24 lg:pl-72">
      <slot />
    </div>
  </div>
</template>
