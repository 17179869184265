<script setup lang="ts">
import { version } from "~/package.json";
import { cva } from "class-variance-authority";

export type Props = {
  hasIcon: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  hasIcon: false,
});

const isDrawerOpen = useIsDrawerOpen();
const closeDrawer = () => {
  isDrawerOpen.value = false;
};

const iconClass = computed(() => {
  return cva("text-on-surface-variant", {
    variants: {
      hasIcon: {
        false: "hidden",
        true: "flex",
      },
    },
  })({
    hasIcon: props.hasIcon,
  });
});

const containerClass = computed(() => {
  return cva("flex items-center gap-3 py-2", {
    variants: {
      hasIcon: {
        false: "pl-4",
        true: "pl-1",
      },
    },
  })({
    hasIcon: props.hasIcon,
  });
});
</script>

<template>
  <div :class="containerClass">
    <UIButtonIcon
      icon="ic:outline-menu-open"
      label="Fermer le menu"
      :class="iconClass"
      @click="closeDrawer"
    />
    <div class="flex items-end gap-2">
      <ClientOnly>
        <NuxtImg
          :src="
            $colorMode.value === 'light'
              ? '/images/makhbazti-logo-name.webp'
              : '/images/logohoriz.png'
          "
          alt="Makhbazti logo"
          class="h-7 w-[138px] object-contain object-left"
          width="707"
          height="145"
          sizes="138px"
          loading="lazy"
        />

        <template #fallback>
          <div class="h-7 w-[138px]" />
        </template>
      </ClientOnly>

      <p class="text-xs text-on-surface-variant">v{{ version }}</p>
    </div>
  </div>
</template>
