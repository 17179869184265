<script setup lang="ts">
import { cva } from "class-variance-authority";
import { primaryNavItems, secondaryNavItems } from "@/data/navItems";

export interface Props {
  type: "standard" | "modal";
}

const props = withDefaults(defineProps<Props>(), {
  type: "standard",
});

const isDrawerOpen = useIsDrawerOpen();

const closeDrawer = () => {
  isDrawerOpen.value = false;
};

const containerClass = computed(() => {
  return cva("rounded-r-2xl transition-all", {
    variants: {
      type: {
        standard: "w-72 sticky",
        modal: "fixed inset-0 z-50 bg-surface",
      },
      isDrawerOpen: {
        true: "w-72",
        false: "w-0 overflow-hidden",
      },
    },
  })({
    type: props.type,
    isDrawerOpen: isDrawerOpen.value,
  });
});
</script>

<template>
  <div>
    <div
      v-if="isDrawerOpen"
      class="fixed inset-0 z-40 flex bg-scrim/[32%] sm:hidden"
      @click="closeDrawer"
    />

    <div :class="containerClass">
      <nav
        class="flex min-h-dvh flex-col gap-6 bg-surface px-3 pb-6 pt-2 sm:bg-inherit sm:py-6"
      >
        <div class="space-y-6">
          <LayoutAppNavDrawerHeadline :has-icon="props.type === 'modal'" />

          <ClientOnly>
            <StoreSwitch type="extended" />
            <template #fallback>
              <StoreSwitchSkeletonExtended />
            </template>
          </ClientOnly>
        </div>

        <div class="flex grow flex-col justify-between">
          <div>
            <LayoutAppNavDrawerItem
              v-for="item in primaryNavItems"
              :key="item.label.en"
              v-bind="item"
            />
          </div>
          <div>
            <LayoutAppNavDrawerItem
              v-for="item in secondaryNavItems"
              :key="item.label.en"
              v-bind="item"
            />
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>
