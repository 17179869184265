<script setup lang="ts">
import { version } from "~/package.json";

const isDrawerOpen = useIsDrawerOpen();
const toggleDrawer = () => {
  isDrawerOpen.value = !isDrawerOpen.value;
};
</script>

<template>
  <div class="bg-surface">
    <div class="flex items-center gap-3 bg-surface-container-low px-1 py-2">
      <UIButtonIcon
        icon="ic:outline-menu"
        label="Menu"
        class="text-on-surface-variant"
        @click="toggleDrawer"
      />

      <div class="flex items-end gap-2">
        <ClientOnly>
          <NuxtImg
            :src="
              $colorMode.value === 'light'
                ? '/images/makhbazti-logo-name.webp'
                : '/images/logohoriz.png'
            "
            alt="Makhbazti logo"
            class="h-7 w-[138px] object-contain object-left"
            width="707"
            height="145"
            sizes="138px"
          />

          <template #fallback>
            <div class="h-7 w-[138px]" />
          </template>
        </ClientOnly>

        <p class="text-xs text-on-surface-variant">v{{ version }}</p>
      </div>
    </div>
  </div>
</template>
